.login-page-container-div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page-container-div > div {
    position: absolute;
}

.login-page-container-div > div:first-child {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-size: cover;
    background-attachment: fixed;
    z-index: 1;
}

.login-page-container-div > div:last-child {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

.login-page-form {
    margin-bottom: 45px;
}

.login-page-form-div {
    background-color: #fff;
    border-radius: 6px;
    width: 390px;
    min-height: 591px;
    padding: 30px;
    padding-top: 72px;
    padding-bottom: 45px;
    z-index: 2;
    margin: auto;
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.login-page-form-div img {
    height: 81px;
    position: absolute;
    top: 3px;
    left: 21px;
    background-color: #dec0b0;
    border-radius: 6px;
}

.form-components-title-div {
    margin-top: 45px;
    margin-bottom: 30px;
}

.form-components-text-input-component:not(:first-child) {
    margin-top: 24px;
}

.login-page-button-div {
    width: 100%;
    margin-top: 45px;
    text-align: right;
}

.login-page-other-button-div:not(:last-child) {
    margin-bottom: 9px;
}

.login-page-other-button-NavLink {
    color: #666;
    text-decoration: none !important;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 24px;
    cursor: pointer;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.login-page-other-button-NavLink:hover {
    color: #dec0b0;
}

.login-page-other-button-icon-i {
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 14px;
    color: #666;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.login-page-other-button-NavLink:hover .login-page-other-button-icon-i  {
    color: #dec0b0;
}

.login-page-username-span {
    color: #3498db;
    font-weight: bold;
}