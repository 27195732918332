.admin-dynamic-page-list-page-item-col {
    margin-bottom: 45px;
}

.admin-dynamic-page-list-page-item-div {
  height: 300px;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  padding: 3px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.admin-dynamic-page-list-page-item-html-div {
  position: absolute;
  /* top: -90px; */
  width: max-content;
  transform: scale(0.5);
  z-index: 1;
}

.admin-dynamic-page-list-page-item-cover-div {
  background-color: #fff;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-dynamic-page-list-page-item-div:hover
  .admin-dynamic-page-list-page-item-cover-div {
  opacity: 0;
}

.admin-dynamic-page-list-page-item-name-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  font-weight: 700;
  color: #000;
  text-overflow: ellipsis;
  text-decoration: none !important;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;
  overflow: hidden;
}

.admin-dynamic-page-list-page-item-name-copy-link-div {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-dynamic-page-list-page-item-name-copy-link-div:hover {
    color: #dec0b0;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
