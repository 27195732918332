.typical-face-detail-container-div {
  padding-bottom: 30px;
  min-height: 100vh;
  background-color: #fff;
}

.typical-face-detail-content-div {
  margin-top: 45px;
}

.typical-face-detail-name-div {
  border-bottom: 1px solid #bdc3c7;
  color: #333;
  font-family: "Lobster", cursive;
  font-size: 20px;
  font-weight: 600;
  font-family: Gilroy;
}

.typical-face-detail-name-h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}

.typical-face-detail-created-time-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-style: italic;
  padding: 6px 0 3px;
  font-size: 14px;
  color: #34495e;
}

.typical-face-detail-description-div {
  font-weight: 700;
  font-size: 18px;
  margin: 15px 0 30px !important;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
