.form-components-hr {
  border-top: 1px solid #bdc3c7 !important;
}

.form-components-title-div {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.form-components-loading-text-div {
  text-align: center;
}

.form-components-text-input-required-span {
  font-size: 16px;
}

.form-components-text-input {
  width: 100%;
  border: 1px solid #95a5a6;
  border-radius: 3px;
  padding: 3px 12px;
  height: 40px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-text-input-component {
  position: relative;
  width: 100%;
}

.form-components-text-input-label {
  width: 100%;
  font-weight: bold;
  /* position: absolute;
    background-color: #fff;
    margin-bottom: 0;
    top: -9px;
    left: 9px;
    padding: 0 6px; */
}

.form-components-text-input-component-error .form-components-text-input {
  border-color: #eb2f06 !important;
}

/* .form-components-sun-editor {
    border: 1px solid transparent;
} */

.form-components-sun-editor-error {
  border: 1px solid #eb2f06;
}

.form-components-text-input:focus {
  border-color: #dec0b0 !important;
  outline: none;
}

.form-components-text-input-error {
  margin-top: 6px;
  font-size: 13px;
  color: #eb2f06;
}

.form-components-button {
  border: 1px solid #95a5a6;
  border-radius: 3px;
  background-color: #fff;
  padding: 9px 15px !important;
  padding-bottom: 8px !important;
  font-size: 14px;
  outline: none !important;
  margin-right: 15px;
  /* margin-bottom: 15px; */
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-button:hover {
  background-color: #dec0b0;
  border-color: #dec0b0;
  color: #fff;
}

.form-components-button-icon {
  color: #dec0b0;
  margin-right: 6px;
  font-size: 14px;
}

.form-components-button:hover .form-components-button-icon {
  color: #fff;
}

.form-components-button-disabled {
  background-color: #666 !important;
  border-color: #666 !important;
  color: #95a5a6 !important;
}

.form-components-button-disabled .form-components-button-icon {
  color: #95a5a6 !important;
}

.form-components-button-requesting {
  background-color: #dec0b0 !important;
  border-color: #dec0b0 !important;
  color: #fff !important;
}

.form-components-button-requesting .form-components-button-icon {
  color: #fff;
}

.form-components-errror-div {
  width: 100%;
  text-align: center;
  border: 1px solid #eb2f06;
  border-radius: 3px;
  color: #eb2f06;
  padding: 15px;
  font-size: 14px;
  margin-bottom: 15px;
}

.form-components-modal {
  border: none !important;
}

.form-components-modal .modal-header {
  padding: 9px 15px;
  padding-right: 45px;
  background-color: #dec0b0;
  position: relative;
}

.form-components-modal .modal-header > div {
  color: #fff;
  font-size: 17px;
}

.form-components-modal-header-close-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  cursor: pointer;
}

.form-components-modal-header-close-button > i {
  color: #fff;
  font-size: 18px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-modal-header-close-button:hover > i {
  color: #ecf0f1;
}

.form-components-modal .modal-content {
  border: none;
}

.form-components-modal .modal-footer {
  padding: 0;
}

.form-components-modal .modal-footer .form-components-button {
  margin: 0;
  margin-left: 15px;
}

.form-components-modal-close-button {
  padding: 12px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  color: #666;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-modal-close-button:hover {
  color: #dec0b0;
}

.form-components-confirmation-modal .modal-footer {
  display: flex;
  align-items: center;
}

.form-components-confirmation-modal-button-div {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-confirmation-modal-button-div:first-child:hover,
.form-components-confirmation-modal-button-div:first-child:hover > i {
  color: #dec0b0;
}

.form-components-confirmation-modal-button-div:last-child:hover,
.form-components-confirmation-modal-button-div:last-child:hover > i {
  color: #eb2f06;
}

.form-components-confirmation-modal-button-div > i {
  margin-right: 6px;
}

.form-components-textare {
  resize: none;
  height: auto;
}

.form-image-uploader-img-container-div {
  position: relative;
  border: 1px solid #95a5a6;
  border-radius: 3px;
  overflow: hidden;
  min-height: 32px;
}

.form-image-uploader-img {
  width: 100%;
  /* margin-top: -1px;
    margin-bottom: -1px; */
}

.form-image-uploader-img-col {
  padding-bottom: 30px;
}

.form-image-uploader-command-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 0 6px;
  background-color: #fff;
  /* border: 1px solid #999; */
  border-bottom-left-radius: 6px;
}

.form-image-uploader-command-button-div {
  font-size: 12px;
  padding: 6px 9px;
  /* font-weight: bold; */
  text-decoration: underline;
  color: #34495e;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-image-uploader-command-button-div:hover {
  color: #dec0b0;
}

.form-image-uploader-color-list-wrapper-div {
  padding-top: 9px;
}

.form-image-uploader-color-item-div {
  float: left;
}

.form-image-uploader-color-item-div:not(:last-child) {
  margin-right: 15px;
}

.form-image-uploader-color-item-div .form-components-checkbox-input {
  margin-right: 6px;
}

.form-image-uploader-color-item-div > .form-components-text-input-component {
  font-size: 12px;
}

.form-image-uploader-command-loading-div {
  cursor: default;
  text-decoration: none;
  color: initial !important;
}

.form-input-tabs > .nav-tabs {
  border-bottom: none;
}

.form-input-tabs > .nav-tabs .nav-link {
  font-weight: normal;
}

.form-input-tabs > .tab-content {
  padding-top: 0;
}

.form-components-ck-editor {
  /* margin-top: 6px; */
}

.form-components-ck-editor .ck-content {
  min-height: 450px;
  max-height: 450px;
  outline: none;
  border: 1px solid #999 !important;
  /* border-top: none !important; */
  box-shadow: none !important;
}

.form-components-ck-editor .ck-sticky-panel .ck-sticky-panel__content {
  position: relative !important;
}

.form-image-list-uploader-add-button-div {
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid #999;
  border-radius: 15px;
  padding: 3px 15px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-image-list-uploader-add-disabled-button-div {
  cursor: default !important;
  background-color: #fff !important;
  color: #333 !important;
  border-color: #999 !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.form-image-list-uploader-add-button-div > i {
  margin-right: 6px;
}

.form-image-list-uploader-add-button-div:hover {
  border-color: #dec0b0;
  background-color: #dec0b0;
  color: #fff;
}

.form-image-list-uploader-add-button-div:hover > i {
  color: #fff;
}

.form-components-checkbox-input {
  margin-right: 9px;
}

.form-components-img-guide-span {
  font-weight: normal;
  font-style: italic;
  font-size: 13px;
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.ck.ck-button:not(.ck-color-grid__tile):hover,
a.ck.ck-button:not(.ck-color-grid__tile):hover {
  background-color: #dec0b0 !important;
  color: #fff;
}

.form-ck-editor-word-count-container-div {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 3px 3px;
}

.form-ck-editor-word-count-container-div * {
  color: var(--ck-color-text);
  font: normal normal normal var(--ck-font-size-base) /
    var(--ck-line-height-base) var(--ck-font-face);
}

.form-ck-editor-word-count-container-div > div:not(:last-child) {
  margin-right: 15px;
}

.form-ck-editor-word-count-container-div span {
  background-color: var(--ck-color-text);
  color: #fafafa;
  padding: 1px 5px;
  border-radius: 3px;
}

.form-hash-tag-selector-content-div {
  width: 100%;
  border: 1px solid #95a5a6;
  border-radius: 3px;
  padding: 12px 6px;
  padding-bottom: 18px;
}

.form-hash-tag-selector-content-selected-items-div {
  height: 90px;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #bdc3c7;
  border-radius: 3px;
  padding: 6px;
  position: relative;
}

.form-hash-tag-selector-content-selected-items-div > div {
}

.form-hash-tag-selector-content-selected-items-div > div > div {
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dec0b0;
  display: inline-block;
  padding: 3px 24px;
  border-radius: 15px;
  margin-right: 6px;
  margin-bottom: 6px;
  position: relative;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-hash-tag-selector-content-selected-items-div > div > div:hover {
  background-color: #dec0b0;
  color: #fff;
}

.form-hash-tag-selector-content-selected-items-div > div > div > i {
  position: absolute;
  top: 5px;
  right: 3px;
  color: #e74c3c;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-hash-tag-selector-content-selected-items-add-i {
  color: #2ecc71 !important;
}

.form-hash-tag-selector-content-selected-items-div > div > div:hover > i {
  color: #ecf0f1 !important;
}

.form-hash-tag-selector-content-search-title-span {
  margin-bottom: 6px;
  display: block;
}

.form-hash-tag-selector-content-search-input-div {
  border: 1px solid #bdc3c7;
  border-bottom: none;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 6px;
  /* position: sticky;
    top: 0;
    left: 0;
    width: 100%; */
}

.form-hash-tag-selector-content-search-input-div input {
  border: 1px solid #95a5a6;
  border-radius: 3px;
  width: 100%;
}

.form-hash-tag-selector-content-search-command-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-hash-tag-selector-content-search-command-div > div {
  cursor: pointer;
  text-decoration: underline;
  padding: 6px 9px;
  font-size: 12px;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.form-hash-tag-selector-content-search-command-div > div:hover {
  color: #dec0b0 !important;
}

.form-hash-tag-selector-content-search-input-div + div {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.form-hash-tag-selector-content-search-error-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #e74c3c;
  font-size: 12px;
  padding: 3px 0;
}

.form-components-number-input-component {
  display: flex;
  align-items: center;
}

.form-components-number-input-button-div {
  font-size: 18px;
  color: #95a5a6;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-number-input-button-div.left {
  margin-right: 3px;
}

.form-components-number-input-button-div.right {
  margin-left: 3px;
}

.form-components-number-input-button-div:hover {
  color: #dec0b0;
}

.form-components-number-input-value-div {
  min-width: 51px;
  text-align: center;
}

.form-component-submitting-message-div {
  font-size: 12px;
}

.form-toggle-component > i {
  font-size: 30px;
  cursor: pointer;
  /* transform: scaleX(1.2); */
}

.form-toggle-component > i.on {
  color: #dec0b0;
}

.form-toggle-component > i.off {
  color: #eb2f06;
}

.form-radio-list-item-label:not(:last-child) {
  margin-right: 15px;
}

.form-link-button-div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px; */
  margin-bottom: 6px;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-link-button-div:hover {
  color: #dec0b0;
}

.form-components-video-container-div {
  padding-top: 12px;
  position: relative;
}

.form-components-video-container-div > * {
  border: none;
  width: 100% !important;
}

.form-components-video-background-div {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}

.form-components-video-name-div {
  color: #3b1b0a;
}

.form-color-list-table-header-row-delete-button-div > div {
  padding-top: 0;
}

.form-color-list-table-item-color-code-wrapper-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.form-color-list-table-item-color-code-wrapper-div > div {
  margin-top: 0 !important;
}

.form-color-list-table-item-color-code-color-div {
  width: 12px;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  z-index: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.form-color-list-table-item-color-code-tag-div {
  position: absolute;
  top: 8px;
  left: 18px;
  z-index: 1;
  font-size: 18px;
  font-weight: bold;
  color: #95a5a6;
}

.form-color-list-table-item-color-code-wrapper-div > div > input {
  padding-left: 30px;
}

.form-pagination-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
}

.form-pagination-ul li {
  cursor: pointer;
}

.form-pagination-ul li > a {
  text-decoration: none;
  min-height: 30px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  outline: none;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-pagination-ul li > a:hover {
  color: #dec0b0;
}

.form-pagination-ul li > a > i {
  font-size: 20px;
  /* padding-top: 1px; */
}

.form-pagination-item-active-li > a {
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: #dec0b0 !important;
  text-decoration: underline !important;
}

.form-select {
  background-color: #fff;
}

.form-na-element-wrapper-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-na-element-wrapper-div > i {
  font-size: 60px;
  margin-bottom: 15px;
  color: #dec0b0;
}

.form-na-element-wrapper-div > div {
  font-size: 14px;
}

.form-read-more-button-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-read-more-button-div {
  font-family: Gilroy;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-read-more-button-div:hover {
  color: #dec0b0;
}

.form-read-more-button-div > * {
  font-size: 16px;
}

.form-read-more-button-div > i {
  padding: 0 6px 3px;
  color: #999;
}

.form-count-down-clock-title-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-count-down-clock-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-count-down-clock-border-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cfa38c;
  border-radius: 12px;
  padding: 6px 6px 21px;
}

.form-count-down-clock-item-div {
  /* padding: 0 12px; */
  font-size: 27px;
  font-family: "Cinzel", serif;
  position: relative;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-count-down-clock-item-div > * {
  font-size: 27px;
  font-family: "Cinzel", serif;
  color: #666;
}

.form-count-down-clock-item-subtraction-div {
  margin-right: 15px;
}

.form-count-down-clock-item-day-div {
  margin-left: 15px;
  margin-right: 15px;
}

.form-count-down-clock-item-number-div {
  width: 60px;
}

/* .form-count-down-clock-item-icon-div {
  margin-right: 15px;
} */

.form-count-down-clock-item-icon-div > * {
  color: #000;
}

.form-count-down-clock-item-icon-div i {
  font-size: 27px;
}

.form-count-down-clock-item-text-div {
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  text-transform: uppercase;
}

.form-link {
  color: #333 !important;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-link:hover {
  color: #dec0b0 !important;
}

.form-info-box-wrapper-div {
  border: 1px solid #bdc3c7;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 15px 45px;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-info-box-wrapper-div:hover {
  box-shadow: 2px 2px 1px #dec0b0;
  border: 1px solid #dec0b0;
  transform: translateY(5px);
}

.form-info-box-wrapper-disabled-div {
  transform: translateY(0) !important;
  box-shadow: none !important;
  border: 1px solid #666 !important;
  cursor: default !important;
  opacity: 0.3 !important;
}

.form-info-box-icon-div {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.form-info-box-icon-i {
  font-size: 45px;
  position: absolute;
  color: #999;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-info-box-wrapper-div:hover .form-info-box-icon-i {
  color: #dec0b0;
}

.form-info-box-wrapper-disabled-div .form-info-box-icon-i {
  color: #999 !important;
}

.form-info-box-title-div {
  border-top: 1px solid #bdc3c7;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 15px 15px;
  text-transform: uppercase;
  font-size: 21px;
  color: #333;
}

.form-info-box-description-div {
  font-style: italic;
  color: #999;
  font-size: 14px;
}

.form-link-link {
  color: #333;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-link-link:hover {
  color: #dec0b0;
}

.form-components-link-input-container-div {
  margin-top: 15px;
  position: relative;
  padding-right: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-components-link-input-product-button-div {
  position: absolute;
  right: 0;
  bottom: 12px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-link-input-product-button-div:hover {
  color: #dec0b0;
}

.form-components-select-with-image-container-div {
  border: 1px solid #999;
  border-radius: 6px;
  height: 300px;
  overflow-y: auto;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-select-with-image-item-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  padding-left: 120px;
  border-bottom: 1px solid #999;
  position: relative;
  min-height: 120px;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-select-with-image-item-img-outer-div {
  position: absolute;
  left: 12px;
}

.form-components-select-with-image-item-img-container-div {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.form-components-select-with-image-item-img {
  width: 100%;
}

.form-components-select-with-image-item-name-div {
  font-size: 15px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-components-select-with-image-item-div:hover
  .form-components-select-with-image-item-name-div {
  color: #dec0b0;
}

.form-multi-select-box-item {
  border: 1px solid #dec0b0;
  cursor: pointer;
  /* opacity: 0.8; */
  display: inline-block;
  padding: 3px 24px;
  border-radius: 15px;
  margin: 0 6px 6px 0;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.form-multi-select-box-item:hover {
  /* opacity: 1; */
  border-color: #3b1b0a;
}

.form-multi-select-box-selected-item {
  border-color: #3b1b0a;
  background-color: #3b1b0a;
  color: #fff;
}

.form-countdown-new-clock-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-countdown-new-clock-title-div {
  background-color: #cfa28b;
  color: #fff;
  border-radius: 30px;
  padding: 0px 24px;
  padding-top: 3px;
  font-family: Gilroy;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 6px;
  /*  */
  /* margin-top: 90px; */
}

.form-countdown-new-clock-container-div .form-count-down-clock-border-div {
  border: none;
}

.form-countdown-new-clock-container-div .form-count-down-clock-item-div > * {
  color: #cfa28b;
  font-family: "Open Sans", sans-serif;
  font-size: 60px;
}

.form-countdown-new-clock-container-div .form-count-down-clock-item-text-div {
  bottom: -21px;
  font-weight: normal;
  font-size: 21px;
  color: gray;
}

.form-countdown-new-clock-container-div .form-count-down-clock-item-div {
  min-width: 45px;
  margin: 0;
}

.form-count-down-clock-item-new-div {
  color: #cfa28b;
  margin: 0 9px 3px !important;
}

.form-count-down-clock-item-number-group-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  margin-bottom: 6px;
}

.form-count-down-clock-item-number-group-item-div {
  /* background-color: red; */
  display: flex;
  position: relative;
  height: 72px;
  width: 69px;
}

.form-count-down-clock-item-number-group-item-div > div {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  overflow: hidden;
}

.form-count-down-clock-item-number-group-item-number-div {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.form-count-down-clock-item-number-group-item-number-div.top {
  top: -72px;
  opacity: 0;
}

.form-count-down-clock-item-number-group-item-number-div.middle {
  top: 0;
  opacity: 1;
}

.form-count-down-clock-item-number-group-item-number-div.bottom {
  top: 72px;
  opacity: 0;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
  .form-countdown-new-clock-title-div {
    font-size: 21px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-div > * {
    font-size: 42px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-text-div {
    font-size: 21px;
  }

  .form-count-down-clock-item-number-group-item-div {
    width: 51px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-div {
    min-width: 30px;
  }

  .form-count-down-clock-item-new-div {
    font-size: 18px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-text-div {
    bottom: -12px;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
  .form-countdown-new-clock-title-div {
    font-size: 15px;
    letter-spacing: 3px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-div > * {
    font-size: 38px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-text-div {
    font-size: 15px;
  }

  .form-count-down-clock-item-number-group-item-div {
    width: 42px;
  }

  .form-count-down-clock-item-number-div {
    width: 42px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-div {
    min-width: 18px;
  }

  .form-count-down-clock-item-number-group-item-number-div {
    height: 51px;
  }

  .form-count-down-clock-item-number-group-div {
    height: 51px;
  }

  .form-count-down-clock-item-number-group-item-div {
    height: 51px;
  }

  .form-count-down-clock-item-new-div {
    font-size: 18px;
  }

  .form-countdown-new-clock-container-div .form-count-down-clock-item-text-div {
    bottom: -12px;
  }
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
