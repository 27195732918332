.header-container-div {
  background: #875b4e;
  /* box-shadow: 0px 0px 3px 0 #000; */
  position: sticky;
  top: 0px;
  z-index: 99;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.header-container-fixed-div {
  top: 0;
  opacity: 1;
}

.header-menu-container {
  background-color: #3b1b0a;
  border-bottom: 1px solid #666;
}

/* .header-container-fixed-div .top-branch-container-div {
    display: none;
} */

/* .header-container-fixed-div .fixed-menu-logo-div {
    display: block;
} */

@media (max-width: 1199px) {
  .header-container-div {
    /* background-color: #3b1b0a; */
    background-color: #cfa28b;
  }

  .top-branch-bar-cart-icon-i,
  .top-branch-bar-responsive-icon-i {
    color: #ffff !important;
  }
}

@media (max-width: 991px) {
  .header-container-fixed-div .top-branch-container-div {
    display: block;
  }

  /* .header-container-fixed-div .top-branch-logo-col img {
        width: 30px;
    } */
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
}
