.footer-container-div {
  background: #2d3436;
}

.footer-container-div * {
  color: #fff;
}

.footer-container-div > div {
  padding-top: 1px;
  padding-bottom: 1px;
  /* padding-top: 30px;
    padding-bottom: 63px; */
}

.footer-title-div {
  font-family: Gilroy;
  font-size: 16px;
  color: #bdc3c7;
  margin-bottom: 15px;
}

.footer-i-info-div {
  font-family: Gilroy;
  margin-bottom: 9px;
  position: relative;
  padding-left: 30px;
  font-size: 12px;
  margin-bottom: 9px;
  position: relative;
}

.footer-i-info-i {
  position: absolute;
  left: 0;
  top: 3px;
}

.footer-info-link {
  font-family: Gilroy;
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  /* -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s; */
}

.footer-info-link:hover {
  text-decoration: underline;
  color: #fff;
  /* color: #dec0b0; */
}

.footer-info-map-div {
  height: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

.footer-info-map-div .google-map-container-div {
  height: 100%;
}

.footer-email-textbox-title-div {
  font-size: 16px;
  color: #bdc3c7;
  margin-bottom: 15px;
}

.footer-email-textbox-div {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
}

.footer-email-textbox-input {
  color: #fff;
  width: 100%;
  padding: 6px 12px;
  padding-right: 51px;
  border: 1px solid #dec0b0;
  background-color: transparent;
  outline: none;
  font-size: 12px;
}

.footer-email-textbox-send-button-div {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: #dec0b0;
  width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.footer-email-textbox-send-button-div:hover {
  background: #d35400;
}

.footer-social-div {
  /* padding-top: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-button-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  opacity: 1;
  cursor: pointer;
  /* -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s; */
}

/* .footer-social-button-div:hover {
    
} */

.footer-social-youtube-button-div {
  background-color: rgba(196, 48, 43, 0.5);
}

.footer-social-youtube-button-div:hover {
  background-color: #c4302b;
}

.footer-social-twitter-button-div {
  background-color: rgba(0, 172, 238, 0.5);
}

.footer-social-twitter-button-div:hover {
  background-color: #00acee;
}

.footer-social-instagram-button-div {
  background-color: rgba(195, 42, 163, 0.5);
}

.footer-social-instagram-button-div:hover {
  background-color: #c32aa3;
}

.footer-facebook-page-wrapper-div > div,
.footer-facebook-page-wrapper-div > div > span,
.footer-facebook-page-wrapper-div iframe {
  width: 100% !important;
}

.footer-social-logo-i {
  font-size: 36px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  border-radius: 50%;
  padding: 3px;
}

.footer-social-logo-i:not(:last-child) {
  margin-right: 15px;
}

/* .footer-social-logo-i:hover.fa-facebook {
  color: #3b5998;
}

.footer-social-logo-i:hover.fa-youtube {
  color: #c4302b;
}

.footer-social-logo-i:hover.fa-twitter {
  color: #00acee;
}

.footer-social-logo-i:hover.fa-instagram {
  color: #c32aa3;
} */

.footer-info-content-div {
  padding-top: 15px;
}

.footer-sub-social-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  margin-top: 15px;
  margin-bottom: 45px;
  overflow: hidden;
  position: inherit;
}

.footer-sub-social-wrapper-div .footer-info-content-div {
  padding-top: 0;
  font-size: 30px;
}

.footer-sub-social-div > a > i {
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.footer-sub-social-div > a:hover > i {
  color: #bdc3c7 !important;
}

.footer-sub-social-wrapper-div > div:not(.footer-sub-social-line-div) {
  background-color: #2d3436;
  padding: 0 12px;
  margin: 0 6px;
  z-index: 1;
}

.footer-sub-social-line-div {
  position: absolute;
  border-top: 1px solid #fff;
  width: 100%;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
  .footer-sub-social-wrapper-div {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  /* .footer-info-content-div {
    min-height: 180px;
  } */
}

@media (max-width: 991px) {
  /* .footer-info-content-div {
    padding-bottom: 75px;
  } */

  .footer-sub-social-wrapper-div {
    margin-bottom: 120px;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
  .footer-info-map-div {
    height: 300px;
    margin-bottom: 15px;
  }

  .footer-info-content-div {
    padding-bottom: 15px;
  }
}

/* .footer-container-div {
    background: #2d3436;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-container-div * {
    color: #fff;
}

.footer-branch-logo-col {
    padding-bottom: 30px;
    display: flex;
    align-items: center;
}

.footer-branch-logo-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Lobster', cursive;
    font-size: 20px;
}

.footer-branch-logo-div img {
    width: 90px;
    height: 90px;
    margin-right: 15px;
    background-color: #fff;
    border-radius: 50%;
}

.footer-branch-email-input-col {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.footer-email-textbox-title-div {
    font-size: 16px;
    margin-bottom: 6px;
    text-align: right;
    font-family: 'Lobster', cursive;
}

.footer-email-textbox-div {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
}

.footer-email-textbox-input {
    color: #fff;
    width: 100%;
    padding-right: 51px;
    border: 1px solid #dec0b0;
    background-color: transparent;
}

.footer-email-textbox-send-button-div {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background: #dec0b0;
    width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.footer-email-textbox-send-button-div:hover {
    background: #d35400;
}

.footer-info-div {
    margin-top: 30px;
}

.footer-info-map-div iframe {
    height: 100%;
}

.footer-title-div {
    font-size: 16px;
    color: #bdc3c7;
    margin-bottom: 15px;
}

.footer-i-info-div {
    margin-bottom: 9px;
    position: relative;
    padding-left: 30px;
    font-size: 12px;
}

.footer-i-info-i {
    position: absolute;
    left: 0;
    top: 3px;
}

.footer-info-link {
    display: block;
    margin-bottom: 6px;
    font-size: 12px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.footer-info-link:hover {
    text-decoration: underline;
    color: #dec0b0;
}

.footer-hot-key-item {
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #dec0b0;
    display: inline-block;
    padding: 3px 24px;
    border-radius: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.footer-hot-key-item:hover {
    background-color: #dec0b0;
    color: #fff;
}

.footer-social-div {
    margin-top: 15px;
}

.footer-row-hr-div {
    border-top: 1px solid #666;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}

.footer-social-row {
    text-align: center;
    margin-top: 30px;
}

.fa-globle {
    color: #fff;
    font-size: 15px;
    opacity: 0.5;
}

.footer-social-logo-i {
    font-size: 18px;
    cursor: pointer;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
    border-radius: 50%;
    padding: 3px;
}

.footer-social-logo-i:not(:last-child) {
    margin-right: 15px;
}

.footer-social-logo-i:hover.fa-facebook {
    color: #3b5998;
}

.footer-social-logo-i:hover.fa-youtube {
    color: #c4302b ;
}

.footer-social-logo-i:hover.fa-twitter {
    color: #00acee;
}

.footer-social-logo-i:hover.fa-instagram {
    color: #c32aa3 ;
}

@media (max-width: 991px) {
    .footer-email-textbox-title-div {
        text-align: left;
        margin-top: 30px;
    }
}

@media (max-width: 991px) {
    .footer-container-div {
        padding-bottom: 90px;
    }
} */
