.product-paging-product-item-col .product-list-carousel-item-div {
  transform: scale(1);
}

.product-paging-product-item-col {
  padding-bottom: 30px;
}

.product-paging-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.product-paging-filter-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
}

.product-paging-filter-number-of-options-div > div {
  width: 75px;
}

.product-paging-filter-number-of-product-div {
  position: absolute;
  left: 15px;
  font-weight: bold;
}

.product-paging-filter-search-input-div {
  margin-right: 15px;
  width: 120px;
}

.product-paging-filter-search-input-div input {
  border: none;
  border-bottom: 1px solid #95a5a6 !important;
}

.product-paging-left-column-wrapper-div {
  margin: 15px 0;
}

.product-paging-left-column-title-div {
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #95a5a6;
  padding-bottom: 3px;
  margin-top: 23px;
  margin-bottom: 9px;
}

.product-paging-left-column-option-div {
  cursor: pointer;
  font-size: 14px;
  padding: 9px 0;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 18px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-paging-left-column-option-div > i {
  font-size: 9px;
  position: absolute;
  top: 15px;
  left: 0;
}

.product-paging-left-column-option-all-div {
  text-transform: none;
  padding-left: 0;
}

.product-paging-left-column-option-div:hover {
  color: #dec0b0;
}

.product-paging-left-column-option-selected-div {
  color: #dec0b0!important;
  font-weight: bold;
}

.product-paging-category-tags-wrapper-div {
  overflow: hidden;
  max-height: 0;
  margin-bottom: 0;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-paging-left-column-option-selected-div
  + .product-paging-category-tags-wrapper-div {
  max-height: 500px;
  margin-bottom: 12px;
}

.product-paging-category-tags-wrapper-div > div {
  font-size: 10px;
  margin-right: 6px;
}

.product-paging-sale-wrapper-div > .product-paging-left-column-option-div {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 30px;
  color: #e74c3c;
}

.product-paging-sale-wrapper-div > .product-paging-left-column-option-div > i {
  font-size: 18px;
  top: initial;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
  .product-paging-left-column-col {
    border-right: 1px solid #ced6e0;
  }
}

@media (max-width: 767px) {
  .product-paging-product-item-col:nth-child(odd) {
    padding-right: 9px;
  }

  .product-paging-product-item-col:nth-child(even) {
    padding-left: 9px;
  }
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
