.product-slider-list-title-div {
    border-bottom: 1px solid #999;
    padding: 9px 14px;
    font-weight: bold;
}

.product-slider-list-container .product-list-carousel-item-outer-div {
    transform: scale(1);
    opacity: 1;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}