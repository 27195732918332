.top-branch-bar-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 72px;
  max-height: 72px;
  overflow: hidden;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

/* .top-branch-bar-container-fixed-height-div {
    min-height: 75px !important;
    max-height: 75px !important;
}

.top-branch-bar-container-fixed-height-div .top-branch-bar-logo-div img {
    height: 150px !important;
} */

.top-branch-bar-responsive-container-top {
  min-height: 63px;
  max-height: 63px;
}

.header-container-fixed-div .top-branch-bar-container-div {
  min-height: 45px;
  max-height: 45px;
}

.top-branch-bar-container-div > div {
  width: 100%;
  min-height: 69px;
  max-height: 69px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.header-container-fixed-div .top-branch-bar-container-div > div {
  min-height: 45px;
  max-height: 45px;
}

.top-branch-bar-container-div > div:not(:nth-child(2)) {
  align-items: center;
}

.top-branch-bar-responsive-container-top > div:not(:nth-child(2)) {
  padding-bottom: 0;
}

/* .header-container-fixed-div .top-branch-bar-container-div > div:not(:nth-child(2)) {
    align-items: center;
    padding-bottom: 0;
} */

.top-branch-bar-hotline-div {
  display: flex;
  align-items: center;
}

.top-branch-bar-hotline-number-div {
  color: #E7C3B1;
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.top-branch-bar-hotline-number-a {
  font-weight: bold;
  color: #E7C3B1 !important;
  text-decoration: none !important;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 18px;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
}

.top-branch-bar-hotline-number-a:hover {
  color: #cfa38c !important;
}

.top-branch-hotline-social-i {
  border-left: 1px solid #999;
  font-size: 21px;
  padding: 0 12px;
  cursor: pointer;
  color: #666;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
}

.top-branch-hotline-social-facebook-i {
  margin-left: 12px;
  color: #666;
}

.top-branch-hotline-social-facebook-i:hover {
  color: #3b5998;
}

.top-branch-hotline-social-youtube-i {
  color: #666;
}

.top-branch-hotline-social-youtube-i:hover {
  color: #c4302b;
}

.top-branch-bar-logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-branch-bar-logo-div img {
  height: 72px;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
}

.header-container-fixed-div .top-branch-bar-logo-div img {
  height: 45px;
}

.top-branch-bar-search-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-branch-bar-search-input-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-branch-bar-search-input {
  background-color: #E7C3B1;
  border: 1px solid #666;
  border-radius: 3px;
  width: 210px;
  padding: 3px 0;
  padding-left: 15px;
  padding-right: 36px;
  font-family: Gilroy;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-branch-bar-search-input:focus {
  border-color: #dec0b0;
}

.top-branch-bar-search-input-icon-i {
  position: absolute;
  right: 15px;
  color: #666;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-branch-bar-search-input:focus + .top-branch-bar-search-input-icon-i {
  color: #dec0b0;
}

.top-branch-bar-cart-div,
.top-branch-bar-responsive-cart-div {
  margin: 3px;
  /* margin-left: 15px; */
  padding: 3px;
  padding-top: 6px;
  cursor: pointer;
  height: 100%;
}

.top-branch-bar-cart-div {
  position: relative;
}

.top-branch-bar-cart-number-of-item-div {
  position: absolute;
  top: -9px;
  right: 0;
  background-color: #e74c3c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  font-size: 12px;
  border-radius: 50%;
}

.top-branch-bar-responsive-cart-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45px;
  height: 100%;
}

.top-branch-bar-cart-icon-i,
.top-branch-bar-responsive-icon-i {
  font-size: 21px;
  color: #E7C3B1;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-branch-bar-cart-div:hover .top-branch-bar-cart-icon-i {
  color: #dec0b0;
}

.top-branch-bar-responsive-cart-div:hover .top-branch-bar-responsive-icon-i {
  color: #dec0b0;
}

.top-branch-bar-responsive-icon-i {
  position: absolute;
  z-index: 0;
  opacity: 0;
}

.top-branch-bar-cart-show-icon-i {
  z-index: 1;
  opacity: 1;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
  #TopMenuBarContainerDiv .top-branch-bar-cart-div {
    display: none;
  }

  .top-branch-bar-logo-div img {
    height: 45px;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
