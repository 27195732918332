.typical-face-distributor-item-div {
  overflow: hidden;
  padding: 15px 0;
  padding-bottom: 15px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  /* transform: rotateY(90deg); */
}

.typical-face-item-show-div {
  /* transform: rotateY(0); */
}

.typical-face-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typical-face-img-boder-div {
  width: 100%;
  /* width: 180px;
    height: 180px; */
  overflow: hidden;
  border-radius: 6px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-face-distributor-item-div:hover .typical-face-img-boder-div {
  /* border-radius: 6px; */
}

.typical-face-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transform: scale(1); */
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-face-distributor-item-div:hover .typical-face-img {
  transform: scale(1.2);
}

.typical-face-title-div {
  padding: 15px 0 9px;
  /* color: #333;
  text-align: center;
  font-family: "Lobster", cursive;
  font-size: 20px;
  font-weight: 600;
  font-family: Gilroy; */
}

.typical-face-description-div {
  text-align: center;
  font-size: 14px;
}

.typical-face-facebook-a {
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
  display: block;
  text-decoration: none !important;
  font-family: Gilroy;
  font-weight: bold;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-face-facebook-a * {
  color: #3b5998;
}

.typical-face-facebook-a:hover {
  background-color: #3b5998;
  color: #fff;
}

.typical-face-facebook-a:hover * {
  color: #fff;
}

.typical-face-distributor-item-link {
  text-decoration: none !important;
}

.typical-face-distributor-district-list-wrapper-div {
  margin-top: 15px;
  padding: 15px 12px;
  height: 300px;
  border: 1px solid #dec0b0;
  border-radius: 12px;
}

.typical-face-distributor-district-list-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 9px;
  margin-bottom: 15px;
}

.typical-face-distributor-district-list-div > div {
  width: max-content;
  margin-top: 0 !important;
}

.typical-face-distributor-district-list-div > div:first-child {
  margin-right: 6px;
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
  .typical-face-distributor-item-div {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (max-width: 767px) {
  /* .typical-face-distributor-item-div {
        margin-bottom: 0;
    } */

  /* .typical-face-img-boder-div {
        width: 150px;
        height: 150px;
    } */
}

@media (max-width: 575px) {
  /* .typical-face-img-boder-div {
        width: 120px;
        height: 120px;
    } */
}
