.admin-banner-item-detail-product-select-container-div {
  margin-top: 12px;
}

.admin-banner-item-detail-product-select-container-div > div {
  height: 0;
  border: 1px solid transparent;
}

.admin-banner-item-detail-product-select-active-container-div > div {
  border: 1px solid #999;
  height: 300px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
