.table-component-container-div {
    overflow-x: auto;
}

.table-component-table {
    width: 100%;
}

.table-component-header {
    border-bottom: 3px solid #dec0b0;
}

.table-component-header * {
    color: #dec0b0;
    font-size: 16px;
}

.table-component-row {
    border-bottom: 1px solid #bdc3c7 ;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.table-component-row:nth-child(odd) {
    background-color: #f1f2f6;
}

.table-component-table th, .table-component-table td {
    padding: 15px 3px;
    vertical-align: top;
}

.table-component-title-link-div {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    color: #283038;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.table-component-title-link-div:hover {
    color: #dec0b0;
}

.table-component-no-item-row {
    text-align: center;
}

.table-component-image-div {
    width: 120px;
    margin: 15px 0;
}

.table-component-image-large-div {
    width: 360px;
    margin: 15px 0;
}

.table-component-image-div > img, .table-component-image-large-div > img {
    width: 100%;
}

.table-component-video-large-div {
    width: 360px;
    margin: 15px 0;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}