.admin-layout-container-div {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.admin-layout-component-div {
    /* background-color: #ecf0f1; */
    padding: 0px;
    padding-left: 240px;
    padding-right: 0;
    min-height: 100vh;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.admin-layout-component-div > div {
    position: relative;
}

.admin-layout-menu-container-div-collapse .admin-layout-component-div {
    padding-left: 15px;
}

.admin-layout-component-title-div, .admin-layout-component-content-div {
    padding: 15px;
}

.admin-layout-component-title-div {
    position: absolute;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #999;
    font-size: 30px;
    background-color: #fff;
    z-index: 1;
}

.admin-layout-component-content-div {
    height: 100vh;
    overflow-y: auto;
}

.admin-layout-component-content-div > div {
    min-height: 100%;
    position: relative;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {
    .admin-layout-component-div {
        padding-left: 15px !important;
        padding-right: 0 !important;
    }
}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}