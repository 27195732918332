.admin-menu-list-title-div {
  /* border-bottom: 1px solid #999; */
  position: relative;
  overflow: hidden;
  padding-bottom: 9px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-menu-list-title-text-div {
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  z-index: 1;
  padding: 15px;
}

.admin-menu-list-title-hr-div {
  position: absolute;
  width: 100%;
  border-top: 1px solid #bdc3c7;
}

.admin-menu-list-note-div {
  background-color: #ffeaa7;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  border-radius: 6px;
}

.admin-menu-list-item-row {
  border-top: 1px solid #bdc3c7;
  /* border-bottom: 1px solid #bdc3c7; */
  padding: 6px 0;
}

.admin-menu-list-item-row > div {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding-right: 0; */
}

.admin-menu-list-item-row > div:first-child {
  /* padding-left: 0; */
}

.admin-menu-list-add-submenu-col {
  /* margin-top: 15px; */
  padding-left: 0;
  padding-right: 0;
  /* padding-left: 0; */
  /* margin-bottom: 30px; */
}

.admin-menu-list-add-submenu-col .admin-menu-list-item-row > div {
  /* padding-left: 45px; */
}

.admin-menu-list-item-row > div > div,
.admin-menu-list-item-row > div input {
  width: 100%;
}

.admin-menu-list-item-row:nth-child(odd) {
  background-color: #f1f2f6;
}

.admin-menu-list-add-menu-button-div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0;
  margin-top: 0;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-menu-list-add-menu-button-div > i {
  margin-right: 12px;
}

.admin-menu-list-add-menu-button-div:hover {
  color: #dec0b0;
}

.admin-menu-list-delete-button-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-menu-list-delete-button-div:hover {
  color: #dec0b0;
}

.admin-menu-list-add-submenu-button-div {
  padding-top: 3px;
  padding-bottom: 21px;
  border-top: 1px solid #bdc3c7;
  font-size: 14px;
  justify-content: flex-start;
}

.admin-menu-list-delete-col {
  justify-content: flex-end;
}

.admin-menu-list-delete-col .form-components-button {
  margin-right: 0;
}

.admin-menu-list-submenu-wrapper-div {
  padding-left: 60px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
  .admin-menu-list-item-row > div {
    margin-bottom: 15px;
  }

  .admin-menu-list-item-row > div:nth-child(2) > div {
    text-align: right;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
