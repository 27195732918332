.contact-form-wrapper-div {
}

.contact-form-title-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  font-size: 24px;
  font-family: "Tuv";
  position: relative;
}

.contact-form-title-wrapper-div > div:first-child {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  padding: 0 15px;
}

.contact-form-title-wrapper-div > div:last-child {
  position: absolute;
  z-index: 1;
  width: 100%;
  border-top: 1px solid #34495e;
}

.contact-form-page-title-div {
  font-weight: bold;
  font-family: "Tuv";
  font-size: 21px;
  color: #dec0b0;
}

.contact-form-page-description-div {
  color: #7f8c8d;
  margin-top: 6px;
  font-size: 12px;
}

.contact-form-page-info-wrapper-div {
  margin-top: 30px;
}

.contact-form-page-info-div {
  position: relative;
  padding-left: 90px;
  margin-bottom: 15px;
}

.contact-form-page-info-div:last-child {
    margin-bottom: 45px;
}

.contact-form-page-info-div > b {
  position: absolute;
  left: 0;
}

.contact-form-page-info-div > a {
    color: initial;
    text-decoration: underline;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.contact-form-page-info-div > a:hover {
    color: #dec0b0;
}

.contact-form-page-info-google-map-div > div > iframe {
  height: 300px !important;
}

.contact-form-page-contact-form-col {
    padding-top: 45px;
    padding-bottom: 150px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
