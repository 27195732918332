.event-item-link {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0 0 30px;
    position: relative;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
    /*  */
    /* transform: scale(0); */
}

.event-item-show-link {
    /* transform: scale(1);    */
}

.event-item-background-img {
    height: 300px;
    width: 100%;
    transform: scale(1);
    object-fit: cover;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.event-item-link:hover .event-item-background-img {
    transform: scale(1.1);
}

.event-item-info-div {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #0000009e;
    padding: 15px;
    width: 100%;
}

.event-item-info-div * {
    color: #fff;
}

.event-item-info-title-div {
    font-family: Gilroy;
    font-size: 15px;
    font-weight: bold;
}

.event-item-info-date-div {
    font-family: Gilroy;
    margin-top: 9px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.event-item-info-date-icon-i {
    margin-right: 6px;
}

.event-item-scheduled-notification-div {
    position: absolute;
    right: -42px;
    top: -63px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #dec0b0;
    color: #fff;
    padding: 90px 45px 0;
    transform: rotate(45deg);
    transform-origin: center bottom;
    font-weight: bold;
}