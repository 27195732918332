.admin-typical-face-hot-face-row {
    display: none;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}