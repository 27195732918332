.admin-footer-form-google-map-wrapper-div {
  padding: 0 0px;
  margin-top: 12px;
}

.admin-footer-form-title-div {
  /* font-size: 18px; */
}

.admin-footer-form-input-col {
  margin-top: 6px;
  margin-bottom: 15px;
}

.admin-footer-form-input-col > div {
  width: 100%;
}

.admin-footer-form-input-row > div {
  display: flex;
  align-items: center;
}

.admin-footer-form-input-delete-col {
  justify-content: flex-end;
}

.admin-footer-form-input-delete-col > div {
  text-align: right;
}

.admin-footer-fomr-social-link-col {
  flex-direction: column;
  margin-bottom: 30px;
}

.admin-footer-fomr-social-link-icon-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.admin-footer-fomr-social-link-icon-div > i {
  font-size: 60px;
}

.admin-footer-fomr-social-link-icon-div > div {
  margin-top: 6px;
  margin-bottom: 30px;
  border-bottom: 1px solid #999;
  width: 100%;
  text-align: center;
  padding: 12px;
  padding-bottom: 3px;
}

.admin-footer-fomr-social-link-content-div {
  border-radius: 12px;
  width: 100%;
  padding: 30px;
  padding-bottom: 45px;
}

.admin-footer-fomr-social-link-input-div > div {
  margin-top: 6px !important;
}

.admin-footer-fomr-social-link-content-facebook-div {
  border: 1px solid #3b5998;
}

.admin-footer-fomr-social-link-content-facebook-div
  .admin-footer-fomr-social-link-icon-div
  > i {
  color: #3b5998;
}

.admin-footer-fomr-social-link-content-youtube-div {
  border: 1px solid #ff0000;
}

.admin-footer-fomr-social-link-content-youtube-div
  .admin-footer-fomr-social-link-icon-div
  > i {
  color: #ff0000;
}

.admin-footer-fomr-social-link-content-twitter-div {
  border: 1px solid #00acee;
}

.admin-footer-fomr-social-link-content-twitter-div
  .admin-footer-fomr-social-link-icon-div
  > i {
  color: #00acee;
}

.admin-footer-fomr-social-link-content-instagram-div {
  border: 1px solid #e1306c;
}

.admin-footer-fomr-social-link-content-instagram-div
  .admin-footer-fomr-social-link-icon-div
  > i {
  color: #e1306c;
}

.admin-footer-fomr-social-link-content-shopee-div {
  border: 1px solid #f05d40;
}

.admin-footer-fomr-social-link-content-shopee-div
  .admin-footer-fomr-social-link-icon-div
  > i {
  color: #f05d40;
}

.admin-footer-fomr-social-link-content-icon-img {
  height: 60px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
