.news-detail-container-div {
    padding-bottom: 30px;
    min-height: 100vh;
    background-color: #fff;
}

.news-detail-content-div {
    margin-top: 45px;
}

.news-detail-name-div {
    border-bottom: 1px solid #bdc3c7;
}

.news-detail-name-h1 {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

.news-detail-created-time-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: italic;
    padding: 6px 0 3px;
    font-size: 14px;
    color: #34495e;
}

.news-detail-description-div {
    font-weight: 700;
    font-size: 18px;
    margin: 15px 0 30px !important;
}

.typical-face-detail-container-div .typical-face-facebook-a {
    width: fit-content;
}

.typical-face-detail-row > div {
    margin-top: 45px;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}