.typical-face-star-item-div {
  overflow: hidden;
  padding: 15px 0;
  padding-bottom: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  /* transform: rotateY(90deg); */
}

.typical-face-star-item-div > a {
  text-decoration: none !important;
}

.typical-face-item-show-div {
  /* transform: rotateY(0); */
}

.typical-face-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typical-face-img-boder-div {
  width: 100%;
  /* width: 180px;
    height: 180px; */
  overflow: hidden;
  border-radius: 6px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-face-star-item-div:hover .typical-face-img-boder-div {
  /* border-radius: 6px; */
}

.typical-face-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transform: scale(1); */
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-star-face-img {
  height: auto !important;
}

/* .typical-face-star-item-div:hover .typical-face-img {
    transform: scale(1.2);
} */

.typical-face-title-div {
  padding: 15px 0 9px;
  /* color: #666;
  text-align: center;
  font-family: "Lobster", cursive;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Gilroy;
  margin-bottom: 9px; */
}

.typical-face-description-div {
  color: #666;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 3px;
}

.typical-face-facebook-a {
  text-align: center;
  margin-top: 15px;
  display: block;
  text-decoration: none !important;
  font-family: Gilroy;
  font-weight: 400;
  border: 1px solid #666;
  padding: 9px 12px;
  border-radius: 3px;
  text-transform: uppercase;
  color: #666;
  margin-bottom: 30px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-face-facebook-a * {
  color: #666;
}

.typical-face-facebook-a:hover {
  color: #3b5998;
  border-color: #3b5998;
}

.typical-face-facebook-a:hover * {
  color: #3b5998;
}

/* .typical-container-div .carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    border-color: #dec0b0  !important;
    background-color: #dec0b0 !important;
}

.typical-container-div .carousel-indicators {
    z-index: 2;
} */

.typical-container-div .carousel-indicators {
  z-index: 2;
}

.typical-container-div .carousel-indicators li {
  border: 1px solid #333 !important;
  border-radius: 15px;
  width: 9px !important;
  height: 9px !important;
  margin: 0 9px !important;
  background-color: #fff !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.typical-container-div .carousel-indicators li:hover {
  border-color: #dec0b0 !important;
}

.typical-container-div .carousel-indicators li.active {
  width: 30px !important;
  background-color: #dec0b0 !important;
  border-color: #dec0b0 !important;
}

.typical-face-carousel .typical-face-carousel-item {
  display: block !important;
}

/* .typical-face-carousel-item {
    display: block !important;
    opacity: 0;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
}

.typical-face-carousel-item.active {
    z-index: 1;
    background-color: #fff;
    opacity: 1;
} */

.kol-item-container-div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.kol-img-container-div {
  width: 210px;
  height: 210px;
  overflow: hidden;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.kol-target-item-container-div .kol-img-container-div {
  border: 3px solid #3b1b0a;
}

.kol-img {
  width: 100%;
}

.kol-name-h2 {
  text-align: center;
  color: #875b4e;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 30px;
  text-transform: uppercase;
}

.kol-name-div {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 6px;
}

.kol-arrow-i {
  font-size: 45px;
  opacity: 0;
  color: #cfa28b;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.kol-target-item-container-div .kol-arrow-i {
  opacity: 1;
}

.kol-description-div {
  background-color: #cfa28b;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 0 15px;
  padding: 15px 60px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.kol-description-div,
.kol-description-div * {
  color: #fff !important;
  font-size: 18px !important;
}

.kol-description-div > * {
  margin: 0 !important;
}

.kol-description-div > i {
  position: absolute;
  font-size: 12px !important;
}

.kol-description-div > i:first-child {
  top: 15px;
  left: 30px;
}

.kol-description-div > i:last-child {
  bottom: 15px;
  right: 30px;
}

.kol-description-div * {
  background-color: transparent !important;
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
  /* .kol-img-container-div {
    width: 300px;
    height: 300px;
  } */
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
  .typical-face-star-item-div {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (max-width: 767px) {
  /* .typical-face-star-item-div {
        margin-bottom: 0;
    } */

  /* .typical-face-img-boder-div {
        width: 150px;
        height: 150px;
    } */

  .typical-container-div {
    padding-top: 0;
  }

  .typical-face-star-item-div {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  /* .typical-face-img-boder-div {
        width: 120px;
        height: 120px;
    } */
}
