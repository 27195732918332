.top-menu-bar-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top-menu-bar-main-item-element {
  cursor: pointer;
  padding: 15px 15px;
  cursor: pointer;
  text-decoration: none !important;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600 !important;
  font-family: Gilroy;
  color: #cfa38c !important;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-main-item-element:hover {
  color: #dec0b0 !important;
}

.top-menu-bar-main-item-element-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
}

.top-menu-bar-main-item-element-bottom-border-div {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  border-top: 4px solid #dec0b0;
  transform: scaleX(0);
}

.top-menu-bar-main-item-element-container-div:hover
  .top-menu-bar-main-item-element-bottom-border-div {
  transform: scaleX(1);
}

.top-menu-bar-main-item-element-collapse-icon-i {
  margin-left: 9px;
  color: #cfa38c !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-main-item-sub-new-div {
  position: absolute;
  top: 100%;
  z-index: 9;
  min-width: 210px;
  background-color: #3b1b0a;
  border: 1px solid #3b1b0a;
  border-top-color: #3b1b0a;
  border-radius: 0 0 6px 6px;
  transform-origin: top center;
  transform: scaleY(0);
  opacity: 0;
  padding: 6px 6px 120px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-main-item-element-container-div:hover
  .top-menu-bar-main-item-sub-new-div:not(.top-menu-bar-main-item-sub-menu-sub-menu-div) {
  opacity: 1;
  transform: scaleY(1);
}

.top-menu-bar-main-item-sub-new-div > * {
  padding: 12px 12px;
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Gilroy;
  font-weight: 600 !important;
  color: #cfa38c !important;
  text-transform: uppercase;
  border-bottom: 1px solid #cfa38c;
  -webkit-transition: all 0.3s linear 0.3s, color 0.15s;
  -moz-transition: all 0.3s linear 0.3s, color 0.15s;
  -o-transition: all 0.3s linear 0.3s, color 0.15s;
  transition: all 0.3s linear 0.3s, color 0.15s;
}

.top-menu-bar-main-item-sub-new-div
  > *
  .top-menu-bar-main-item-element-bottom-border-div {
  display: none;
}

.top-menu-bar-main-item-sub-new-div
  > *
  .top-menu-bar-main-item-element-collapse-icon-i {
  position: absolute;
  right: 12px;
}

.top-menu-bar-main-item-sub-menu-sub-menu-div {
  padding: 0;
  padding-left: 30px;
  border: none;
  opacity: 1;
  transform: scaleY(1);
}

.top-menu-bar-main-item-sub-menu-sub-menu-div > * {
  display: block;
  padding: 0;
  border: none;
}

.top-menu-bar-main-item-sub-menu-sub-menu-div {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  -webkit-transition: all 0.3s linear 0.3s, color 0.15s;
  -moz-transition: all 0.3s linear 0.3s, color 0.15s;
  -o-transition: all 0.3s linear 0.3s, color 0.15s;
  transition: all 0.3s linear 0.3s, color 0.15s;
}

.top-menu-bar-main-item-sub-new-div:hover
  > .top-menu-bar-main-item-sub-menu-sub-menu-div {
  max-height: 10000px;
  opacity: 1;
}

.top-menu-bar-responsive-container-outer-div {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  height: 0;
  transform-origin: top right;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-responsive-opened-container-outer-div {
  height: 200%;
  z-index: 1;
}

.top-menu-bar-responsive-container-div {
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;
  padding: 15px 0;
  padding-top: 0;
  border: none;
  margin-bottom: 1px;
  transform: scaleX(0);
  transform-origin: top right;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-responsive-container-div .top-menu-bar-main-item-element {
  display: block;
  width: 100%;
  padding: 15px 12px !important;
  padding-right: 30px !important;
  padding-left: 0 !important;
  color: #fff !important;
  font-weight: normal;
  border-top: 1px solid #fff;
}

.top-menu-bar-responsive-container-div > * {
  color: #fff !important;
}

.top-menu-bar-responsive-container-div:hover
  .top-menu-bar-main-item-element
  > i {
  color: #fff !important;
}

.top-menu-bar-responsive-container-div
  .top-menu-bar-main-item-sub-item-element {
  padding: 15px 0;
  color: #333;
}

.top-menu-bar-responsive-container-div .top-branch-bar-search-div {
  padding: 15px 15px;
  padding-right: 0;
}

.top-branch-bar-search-div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
}

.top-menu-bar-responsive-container-div .top-branch-bar-search-input-div {
  padding-left: 0;
  padding-right: 0;
}

.top-menu-bar-responsive-container-div .top-branch-bar-search-input-div,
.top-menu-bar-responsive-container-div .top-branch-bar-search-input {
  width: 100%;
}

.top-menu-bar-responsive-container-div .top-branch-bar-cart-icon-i {
  color: #e7c3b1;
}

.top-menu-bar-responsive-opened-container-div {
  transform: scaleX(1);
}

.top-menu-bar-responsive-opened-container-div > div {
  position: absolute;
  background-color: #cfa28b;
  right: 0;
  margin-bottom: 0 !important;
  width: 300px;
  z-index: 2;
  padding-top: 75px;
}

.top-menu-bar-responsive-container-background-div {
  opacity: 0.5;
  position: absolute !important;
  background-color: #000 !important;
  right: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  z-index: 1 !important;
}

.top-menu-bar-responsive-container-content-div {
  overflow-y: auto;
}

.top-menu-bar-responsive-container-top-bar-div {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  width: 300px;
  background-color: white;
}

.top-menu-bar-responsive-container-top-bar-div > div {
  width: 100%;
}

.top-branch-bar-close-button-div {
  height: 30px;
  padding: 0 12px !important;
  font-size: 21px;
  color: #cfa28b;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-branch-bar-close-button-div:hover {
  color: #cfa28b;
}

.top-menu-bar-main-item-responsive-menu-wrapper-div {
  padding: 0 15px;
}

.top-menu-bar-main-item-responsive-menu-wrapper-sub-menu-div {
  padding-left: 30px;
  padding-right: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  -webkit-transition: all 0.3s linear, color 0.15s;
  -moz-transition: all 0.3s linear, color 0.15s;
  -o-transition: all 0.3s linear, color 0.15s;
  transition: all 0.3s linear, color 0.15s;
}

.top-menu-bar-main-item-responsive-menu-wrapper-sub-menu-opened-div {
  max-height: 10000px;
  opacity: 1;
}

.top-menu-bar-main-item-responsive-element {
  position: relative;
  display: flex !important;
  align-items: center;
}

.top-menu-bar-main-item-responsive-element
  .top-menu-bar-main-item-element-collapse-icon-i {
  position: absolute;
  margin: 0;
  right: 0;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 15px;
  border-left: 1px solid #bdc3c7;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.top-menu-bar-main-item-responsive-menu-wrapper-sub-menu-div
  .top-menu-bar-main-item-responsive-element {
  color: #fff !important;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
  .top-menu-bar-responsive-opened-container-div > div {
    width: 360px;
  }

  .top-menu-bar-responsive-container-top-bar-div {
    width: 360px;
  }
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
