.button-component-container-div {
  width: max-content;
  position: relative;
  background-color: #3b1b0a;
  padding: 15px;
  color: #dec0b0;
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 1px solid #3b1b0a;
  border-radius: 6px;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.button-component-container-div:hover {
  background-color: #fff;
  /* border-color: #dec0b0; */
  color: #3b1b0a;
}

.button-component-has-icon-container-div {
  padding-left: 42px;
}

.button-component-container-div > i {
  position: absolute;
  left: 15px;
  color: #dec0b0;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.button-component-container-div:hover > i {
  color: #3b1b0a;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
